/**
 * Vue Router Configuration
 *
 * This module configures the Vue Router for the application. It:
 * - Defines routes and their associated components.
 * - Ensures that certain routes are protected (require authentication).
 * - Integrates with the Kinde authentication system to manage user access.
 *
 * Components are lazy-loaded for better performance and quicker initial load times.
 *
 * The router defines a global navigation guard that:
 * - Always initializes the Kinde client, as it's essential for login controls.
 * - Checks if a user is authenticated before allowing them to access protected routes.
 */

import { createRouter, createWebHistory } from 'vue-router';
import { initializeKindeClient, getKindeClient } from '@/modules/kindeClient';
import { useUserStore } from '@/stores/userStore';

await initializeKindeClient();
const KindeClient = getKindeClient();
const isAuthenticated = await KindeClient.isAuthenticated();

// Define the application's routes
const routes = [
    {
        path: '/', // Is the default landing page, also the Authentication Callback from Kinde. The Kinde setup logic is done in App.vue on this default route. Don't break this.
        name: 'DefaultHomepage',
        component: () => import('@/components/DefaultHomepage.vue'), // Lazy-load DefaultHomepage component
        beforeEnter: (to, from, next) => {
            // Check if the user is authenticated
            if (isAuthenticated) {
                // Redirect to /home if the user is authenticated
                next('/home');
            } else {
                // Proceed to the default homepage if not authenticated
                next();
            }
        },
    },
    {
        path: '/auth-error',
        name: 'AuthError',
        component: () => import('@/components/AuthError.vue'), // Lazy-load AuthError component
    },
    {
        path: '/home',
        name: 'UserHomepage',
        component: () => import('@/components/UserHomepage.vue'), // Lazy-load UserHomepage component
        meta: { requiresAuth: true }, // This route requires user to be authenticated
    },
    {
        path: '/user-profile/:userID?',
        name: 'UserProfile',
        component: () => import('@/components/UserProfile.vue'), // Lazy-load UserProfile component
        meta: { requiresAuth: true },
        beforeEnter: async (to, from, next) => {
            // If userID is not provided, redirect to a route with current logged in userID
            if (!to.params.userID) {
                const userStore = useUserStore();
                console.log(userStore.user.userID);
                next({ name: 'UserProfile', params: { userID: userStore.user.userID } });
            } else {
                next();
            }
        },
    },
    {
        path: '/garage-details/:userID?',
        name: 'GarageDetails',
        component: () => import('@/components/GarageDetails.vue'), // Lazy-load GarageDetails component
        meta: { requiresAuth: true },
        beforeEnter: async (to, from, next) => {
            // If userID is not provided, redirect to a route with current logged in userID
            if (!to.params.userID) {
                const userStore = useUserStore();
                console.log(userStore.user.userID);
                next({ name: 'GarageDetails', params: { userID: userStore.user.userID } });
            } else {
                next();
            }
        },
    },
    {
        path: '/media-upload',
        name: 'MediaUpload',
        component: () => import('@/components/MediaUpload.vue'), // Lazy-load MediaUpload component
        meta: { requiresAuth: true }, // This route requires user to be authenticated
    },
    {
        path: '/db-test',
        name: 'DatabaseTest',
        component: () => import('@/components/DatabaseTest.vue'), // Lazy-load DatabaseTest component
        meta: { requiresAuth: true },
    },
];

// Create a new Vue Router instance
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Define a global navigation guard to manage user access to routes
router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (!requiresAuth) {
        next();
        return;
    }

    if (KindeClient && typeof KindeClient.isAuthenticated === 'function') {
        const authStatus = await KindeClient.isAuthenticated();

        if (authStatus) {
            // Use the Pinia plugin to centrally add the user ID details to the state
            const userStore = useUserStore();
            if (!userStore.user) {
                await userStore.fetchUserDetails();
            }
        }

        next();
    } else {
        console.error('Kinde client is not available or not properly initialized.');
        next({ name: 'AuthError' });
    }
});

export default router;
