// Import necessary modules from Vue
import { createApp } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css';

// Import the main App component
import App from '@/App.vue';

// Import the router configuration to handle page routing
import router from '@/router';

// Import the Kinde authentication plugin to handle user authentication
import KindeService from '@/services/kindeService';

// Import the Pinia state store
import { createPinia } from 'pinia'

// Import global styles for the application
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';

/**
 * Main Entry Point for the Vue Application
 *
 * This script:
 * - Imports essential modules, components, plugins, and styles.
 * - Initializes the Vue application.
 * - Configures the application with the Kinde authentication plugin and routing system.
 * - Mounts the application to the DOM for rendering.
 *
 * The initialization process is wrapped in an asynchronous IIFE (Immediately Invoked Function Expression)
 * to ensure that any asynchronous operations can be performed seamlessly.
 * Errors during the initialization process are caught and logged to the console.
 */
(async () => {
    try {
        // Create the main Vue application instance
        const app = createApp(App);

        // Create the Pinia store
        const pinia = createPinia()

        // Integrate the Kinde authentication plugin
        app.use(KindeService);

        // Integrate the Quasar component plugin
        app.use(Quasar, quasarUserOptions);

        // Use the router for handling page navigation
        app.use(router);

        // Use the Pinia store
        app.use(pinia)

        // Mount the Vue application to the DOM
        app.mount('#app');
    } catch (error) {
        // Handle any errors that might occur during app initialization
        console.error('Error initializing the app:', error);
        // If needed, add more comprehensive error handling or display an error message to the user
    }
})();
