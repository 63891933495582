import axios from 'axios';
import { initializeKindeClient, getKindeClient, isKindeClientInitialized } from '@/modules/kindeClient';

// Create an axios instance
const api = axios.create({
    baseURL: process.env.VUE_APP_API_URI
});

export const getUserDetails = async () => {

    try {
        // Ensure Kinde Client is ready
        if (!isKindeClientInitialized()) {
            await initializeKindeClient();
        }

        // Get Kinde Client
        const kindeClient = getKindeClient();
        const token = await kindeClient.getToken();
        const kindeUser = await kindeClient.getUserProfile();

        // Always use the POST to /users/userID here as it will store the user in the DB if it doesn't exist but also set the last seen date for an existing user
        await api.post(`/users`, kindeUser, {
            headers: {
                'Content-Type': 'application/json', // Set the correct content type for JSON data
                Authorization: `Bearer ${token}`, // Include the token for authentication
              },
        });

        // GET user details from the database. These will be either the same as the ones just created (for a new user) or may have been changed by an existing user
        const apiResponse = await api.get(`/users/${kindeUser.id}`, {
            headers: {
                'Content-Type': 'application/json', // Set the correct content type for JSON data
                Authorization: `Bearer ${token}`, // Include the token for authentication
                },
        });

        return apiResponse.data.data.results[0];
    } catch (error) {
        console.error('Error getting user details:', error);
        throw error;
    }
};
