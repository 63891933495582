// Import the required functionality to create the Kinde client
import createKindeClient from "@kinde-oss/kinde-auth-pkce-js";

// Import Vue's reactivity functionality for creating reactive references
import { ref } from 'vue';

// Initialize the KindeClient as null. It will later hold the instance of the Kinde client once created
let kindeClient = null;

// Create a reactive reference to monitor the initialization status of the Kinde client
const isKindeInitialized = ref(false);

export async function initializeKindeClient() {
  if (!kindeClient) {
    try {
      kindeClient = await createKindeClient({
        client_id: process.env.VUE_APP_KINDE_CLIENT_ID,
        domain: process.env.VUE_APP_KINDE_DOMAIN,
        redirect_uri: process.env.VUE_APP_KINDE_REDIRECT_URL,
        is_dangerously_use_local_storage: true
      });

      if (kindeClient) {
        isKindeInitialized.value = true;
      }
    } catch (error) {
      console.error("Error initializing the Kinde client:", error);
      throw error;
    }
  } else {
    isKindeInitialized.value = true;
  }
}

export function getKindeClient() {
  return kindeClient;
}

export function isKindeClientInitialized() {
  return isKindeInitialized;
}