import { defineStore } from 'pinia';
import { getUserDetails } from '@/services/userService';

export const useUserStore = defineStore({
    id: 'user', // Unique ID of the store
    state: () => ({
        user: null
    }),
    actions: {
        async fetchUserDetails() {
            try {
                const userDetails = await getUserDetails();
                console.log("Fetch User Details");
                this.user = userDetails; // Directly mutating the state
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        }
    }
});
