// KindeService.js

import { initializeKindeClient, getKindeClient, isKindeClientInitialized } from '@/modules/kindeClient';

const KindeService = {
  install(app) {
    app.provide('$kinde', {
      initialize: initializeKindeClient,
      client: getKindeClient,
      isInitialized: isKindeClientInitialized
    });
  }
};

export default KindeService;
