<template>
  <q-layout view="hHr lpR ffR">

    <q-header reveal bordered class="bg-primary text-white">
      <q-toolbar>
      
        <div class="row items-center full-width q-gutter-none">
          <router-link to="/" >
            <q-avatar size="80px">
              <img src="@/assets/img/logo.svg">
            </q-avatar>
          </router-link>
          <div class="q-pa-md">
            <div class="text-h6">CSNAPIT</div>
            <div class="text-caption text-weight-light text-italic">See it, Snap it, Share it</div>
          </div>
        </div>

        <!-- No User Logged In -->
        <template v-if="!userData">
          <q-btn class="no-wrap q-mr-md" color="secondary" icon="mdi-login" label="Log In" @click="signIn" />
          <q-btn class="no-wrap q-mr-md" color="secondary" icon="mdi-account-plus" label="Create Account" @click="createAccount" />
        </template>

        <q-btn flat round icon="menu" @click="toggleRightDrawer" />
      </q-toolbar>
    </q-header>

    <q-drawer overlay behavior="mobile" v-model="rightDrawerOpen" side="right">
        <q-scroll-area style="height: calc(100% - 150px); margin-top: 150px;">

          <q-list padding class="menu-list">

            <!-- User Logged In Items -->
            <template v-if="userData">
              <!-- Log Out -->
              <q-item @click="logout" clickable v-ripple>
                <q-item-section avatar>
                  <q-icon color="negative" name="mdi-logout" size="30px" />
                </q-item-section>

                <q-item-section>
                  <span class="text-negative">Log Out</span>
                </q-item-section>
              </q-item>

              <q-separator />

              <!-- User Profile -->
              <q-item v-if="userData" :to="{ name: 'UserProfile' }" clickable v-ripple>
                <q-item-section avatar>
                  <q-icon name="mdi-account-settings" size="30px" />
                </q-item-section>

                <q-item-section>
                  Your Profile
                </q-item-section>
              </q-item>

              <!-- User Garage -->
              <q-item v-if="userData" :to="{ name: 'GarageDetails' }" clickable v-ripple>
                <q-item-section avatar>
                  <q-icon name="mdi-garage-open-variant" size="30px" />
                </q-item-section>

                <q-item-section>
                  Your Garage
                </q-item-section>
              </q-item>

            </template>

          

            <!-- Dev/Test -->
            <q-item-label header>Dev/Test Items</q-item-label> 

            <q-item :to="{ name: 'DatabaseTest' }" clickable v-ripple>
              <q-item-section avatar>
                <q-icon name="mdi-database" size="30px" />
              </q-item-section>

              <q-item-section>
                Database Test
              </q-item-section>
            </q-item>

            <q-item :to="{ name: 'MediaUpload' }" clickable v-ripple>
              <q-item-section avatar>
                <q-icon name="mdi-image" size="30px" />
              </q-item-section>

              <q-item-section>
                Image Test
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>

        <!-- User Profile Image -->
        <q-img class="absolute-top" src="@/assets/img/material.webp" style="height: 150px">
          <div class="absolute-bottom bg-transparent">
            <q-btn :to="{ name: 'UserProfile' }" round color="blue-grey-4" class="q-pa-xs">
              <q-avatar>
                <img :src="userData?.profilePicture || '/default_profile.png'">
              </q-avatar>
            </q-btn>
            <div class="text-h5">{{ userData?.displayName || 'Please Log In' }}</div>
            <div>@{{ userData?.displayName || 'userName'}}</div> 
          </div>
        </q-img>

      </q-drawer>

    <q-page-container class="bg-grey-4">
      <router-view />
    </q-page-container>

    <q-footer bordered class="bg-primary text-white">
      <q-toolbar>
        <div class="row items-center full-width q-gutter-none">
          <router-link to="/" >
            <q-avatar size="40px">
              <img src="@/assets/img/logo.svg">
            </q-avatar>
          </router-link>
          <div class="q-pa-md text-subtitle1">CSNAPIT</div>
        </div>
      </q-toolbar>
    </q-footer>

  </q-layout>
</template>


<script>
import { ref, onMounted, inject, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/userStore';

export default {
  name: 'App',
  setup() {
    const userStore = useUserStore();
    const KindeService  = inject('$kinde'); // Inject the kindeService
    const isAuthenticated = ref(false);
    const userData = ref({});
    const loading = ref(true);
    const router = useRouter();
    const rightDrawerOpen = ref(false)

    let KindeClient = null;

    console.log(`VUE_APP_API_URI: ${process.env.VUE_APP_API_URI}`);

    watch(isAuthenticated, async () => {
      try {
        // Navigate the user to their homepage
        router.push({ name: 'UserHomepage' });
      } catch (error) {
        console.error("Error handling the auth callback:", error);
        router.push({ name: 'AuthError' });
      }
    });

    // Watch for changes in userStore.user
    watch(
      () => userStore.user,
      (newUser) => {
        userData.value = newUser; // Update userData.value when userStore.user changes
        console.log("Authenticated user:", userData.value);
      },
      { immediate: true } // This option ensures the watcher runs immediately with the current value
    );

    onMounted(async () => {

      await KindeService.initialize();

      // Check authentication immediately upon mounting
      if (KindeService.isInitialized().value) {

        KindeClient = KindeService.client();
        isAuthenticated.value = await KindeClient.isAuthenticated();
        console.log(KindeClient);

        if (!isAuthenticated.value) {
          console.error("User is not authenticated.");
          router.push({ name: 'DefaultHomepage' });
        }
      }

      simulateLoadingDelay();

      // loading.value = false;

    });

    // Function to simulate loading process
    const simulateLoadingDelay = () => {
      // Set a timeout of 5 seconds (5000 milliseconds)
      setTimeout(() => {
        loading.value = false;
      }, 500);
    };

    const signIn = async () => {
      await KindeClient.login();
    };

    const createAccount = async () => {
      await KindeClient.register();
    };

    const logout = async () => {
      await KindeClient.logout();
      isAuthenticated.value = false;
      userData.value = {};
    };

    return {
      rightDrawerOpen,
      isAuthenticated,
      userData,
      signIn,
      createAccount,
      logout,
      loading,
      toggleRightDrawer () {
        rightDrawerOpen.value = !rightDrawerOpen.value
      }
    };
  }
};
</script>
